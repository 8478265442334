// 日期格式转换
export function date(date) {
	if (date === null) {
		return '';
	} else {
		var d = new Date(date);
		return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日 `;
	}
}

// 用户性别
export function sex(val) {
	if (Number(val) === 0) {
		return '男';
	} else if (Number(val) === 1) {
		return '女';
	} else {
		return '';
	}
}

// 是否与子女同住
export function conhabit(val) {
	if (val === 0) {
		return '否';
	} else if (val === 1) {
		return '是';
	} else {
		return '';
	}
}

// 获取当前年月日时分秒
export const foramtDate = (date) => {
	let res = '';
	if (date) {
		let temp = new Date(date);
		var year = temp.getFullYear();
		var month = temp.getMonth() + 1;
		var day = temp.getDate();
		var hour = temp.getHours();
		var minute = temp.getMinutes();
		month = month < 10 ? '0' + month : month;
		day = day < 10 ? '0' + day : day;
		hour = hour < 10 ? '0' + hour : hour;
		minute = minute < 10 ? '0' + minute : minute;
		res = year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
	}
	return res;
};
